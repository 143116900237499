<template>
  <div class="job-profession-items">
    <JobProfessionItem
      v-for="roleRegulation in roleRegulations"
      :key="roleRegulation.id"
      :roleRegulation="roleRegulation"
      @updateProfileByLike="$emit('updateProfileByLike')"
      @openTestByButton="openTestByButton"
    />
  </div>
</template>

<script>
const JobProfessionItem = () =>
  import("@/components/ProfileComponents/JobFolderComponents/JobProfession/JobProfessionItem");

export default {
  name: "JobProfessionItems",
  mixins: [],
  props: {
    roleRegulations: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  components: { JobProfessionItem },
  data() {
    return {};
  },
  mounted() {},
  methods: {
    openTestByButton(value) {
      this.$emit("openTestByButton", value);
    }
  },
  computed: {},
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/index";
@import "~@/assets/base/breakpoints/breakpoints";

.job-profession-items {
  display: flex;
  flex-wrap: wrap;

  @media screen and (max-width: $md) {
    justify-content: center;
  }
}
</style>
